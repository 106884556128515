import * as UIModel from "entities/UIModel";
import { Document } from "entities/ApiModel/Document";
import { Endorsement } from "entities/ApiModel/Endorsement";
import { JacketFormDetail } from "entities/ApiModel/JacketFormDetail";
import { JacketProduct } from "entities/ApiModel/JacketProduct";
import { JacketSignature } from "entities/ApiModel/JacketSignature";
import { OrderParty } from "entities/ApiModel/OrderParty";
import { Endorsement as EndorsementUI } from "entities/UIModel/Endorsement";
import { Jacket } from "entities/UIModel/Jacket";
import { JacketSignature as JacketSignatureUI } from "entities/UIModel/JacketSignature";
import { EndorsementStatusType } from "utils/data/enum";
import { convertToNumber } from "utils/shared";
import { v4 as uuidv4 } from "uuid";
import { 
  buildTransCodeKey, 
  mapUIPricingDetail, 
  maskZipCode 
} from "../helper";
import { createDocumentOrderData } from "./scFileCreator";
import { 
  JacketAdditionalCharge, 
  JacketSpecialCharge 
} from "entities/ApiModel";

const getOrderParties = (orderParties?: OrderParty[]) => {
  return orderParties ? orderParties.map(buildJacketOrderParty) : [];
};

const buildJacketOrderParty = (p: OrderParty) => {
  return {
    filePartyId: p.FilePartyID || 0,
    identityKey: p.IdentityKey || 0,
    fileID: p.FileID || 0,
    integrationKey: p.IntegrationKey,
    filePartyKey: p.IntegrationKey,
    filePartyDataID: p.FilePartyDataID || 0,
    partyRoleTypeCode: p.PartyRoleTypeCode || "",
    partyTypeCode: p.PartyTypeCode || "",
    orderPartyID: p.OrderPartyID,
    orderPartyDataID: p.OrderPartyDataID,
    orderID: p.OrderID,
    addressOne: p.Address || "",
    addressTwo: p.Address2 || "",
    addressThree: p.Address3 || "",
    city: p.City || "",
    state: {
      code: p.StateCode || "",
      abbr: p.StateAbbr || "",
    },
    zipCode: maskZipCode(p.Zip),
    suffixType: {
      code: p.SuffixTypeCode || "",
      name: p.SuffixTypeName || "",
    },
    isExcludedOnCPL: p.IsExcludedOnCPL ? !!p.IsExcludedOnCPL : false,
    isExcludedOnAAL: p.IsExcludedOnAAL ? !!p.IsExcludedOnAAL : false,
    partyFirstName: p.PartyFirstName || "",
    partyMiddleName: p.PartyMiddleName || "",
    partyLastName: p.PartyLastName || "",
    companyName: p.PartyCompanyName || "",
    individualAttorney: p.PartyAdditionalName || "",
    loanNumber: p.LoanNumber || "",
    attentionTo: p.AttentionTo || "",
    successorLanguage: p.SuccessorLanguage || "",
  };
};

const buildDocument = (d: Document) => {
  return {
    integrationKey: d.IntegrationKey,
    id: d.DocumentID,
    fileID: d.FileID,
    orderID: d.OrderID,
    isHidden: d.IsHidden,
    fileTypeCode: d.DocumentFileTypeCode,
    fileName: d.DocumentFileName,
    sourceTypeCode: d.DocumentSourceTypeCode,
    typeCode: d.DocumentTypeCode,
    createdDate: d.DocumentCreatedDate,
    createdByUserName: d.DocumentCreatedByUserName,
  };
};

const buildJacket = (jacket: JacketProduct): Jacket => {
  const newJacket: Jacket = {
    isLocationDefault: false,
    isDirty: jacket.IsDirty,
    id: jacket.ProductID,
    fileID: jacket.FileID,
    orderID: jacket.OrderID,
    address: jacket.Address,
    address2: jacket.Address2,
    address3: jacket.Address3,
    city: jacket.City,
    zipCode: maskZipCode(jacket.Zip),
    issueDate: jacket.IssueDate,
    issueDateTime: jacket.IssueDateTime
      ? new Date(jacket.IssueDateTime)
      : undefined,
    issueDateTimeStr: jacket.IssueDateTime,
    revisionDate: jacket.RevisionDate,
    revisionDateTime: jacket.RevisionDateTime
      ? new Date(jacket.RevisionDateTime)
      : undefined,
    form: jacket.Form || "",
    formPrefix: jacket.FormPrefix,
    formType: jacket.FormType,
    formID: jacket.FormID,
    formVersion: jacket.FormVersion,
    formTemplateName: jacket.FormTemplateName,
    opaIdentifier: jacket.OPAIdentifier,
    isAgencyExclusive: jacket.IsAgencyExclusive,
    insuredNames: jacket.InsuredNames,
    insuredNamesData: JSON.parse(jacket.InsuredNamesData || "{}"),
    orderParties: getOrderParties(jacket.OrderParties),
    identityKey: jacket.IdentityKey,
    integrationKey: jacket.IntegrationKey,
    productReferenceID: jacket.ProductReferenceID,
    originalJacketDate: jacket.OriginalJacketDate,
    originalJacketLiability: jacket.OriginalJacketLiability,
    originalJacketNumber: jacket.OriginalJacketNumber,
    originalJacketState: jacket.OriginalJacketState,
    originalJacketType: jacket.OriginalJacketType,
    originalJacketUnderwriterTypeCode: jacket.OriginalJacketUnderwriterTypeCode,
    orderStatusTypeCode: jacket.OrderStatusTypeCode,
    orderStatusTypeName: jacket.OrderStatusTypeName,
    underwriterTypeCode: jacket.UnderwriterTypeCode || "",
    filePricingDetailID: jacket.FilePricingDetailID,
    pricingDetail: {
      integrationKey: jacket.PricingDetail?.IntegrationKey || uuidv4(),
      orderID: jacket.OrderID || 0,
      fileID: jacket.FileID || 0,
      filePricingID: jacket.PricingDetail?.FilePricingID || 0,
      transCode: jacket.PricingDetail?.TransCode,
      transCodeDescription: jacket.PricingDetail?.TransCodeDescription,
      transCodeKey: buildTransCodeKey(
        jacket.PricingDetail?.TransCode,
        jacket.PricingDetail?.TransCodeDescription
      ),
      filePricingDetailID: jacket.PricingDetail?.FilePricingDetailID || 0,
      pricingRateType: jacket.PricingDetail?.PricingRateType,
      pricingRateTypeData: jacket.PricingDetail?.PricingRateTypeData,
      pricingTransactionTypeCode:
        jacket.PricingDetail?.PricingTransactionTypeCode,
      pricingFeeTypeCode: jacket.PricingDetail?.PricingFeeTypeCode,
      coverageType: jacket.PricingDetail?.CoverageType,
      coverageTypeData: jacket.PricingDetail?.CoverageTypeData,
      liability: jacket.PricingDetail?.Liability || 0.0,
      actualFee: jacket.PricingDetail?.ActualFee || 0.0,
      originalActualFee: jacket.PricingDetail?.ActualFee || 0.0,
      calculatedFee: jacket.PricingDetail?.CalculatedFee || 0.0, // jacket.PricingDetail?.ActualFee || 0.0,
      actualRiskRate: jacket.PricingDetail?.ActualRiskRate || 0.0,
      calculatedRiskRate: jacket.PricingDetail?.CalculatedRiskRate || 0.0,
      actualRemittance: jacket.PricingDetail?.ActualRemittance || 0.0,
      calculatedRemittance: jacket.PricingDetail?.CalculatedRemittance || 0.0,
      actualRetention: jacket.PricingDetail?.ActualRetention || 0.0,
      calculatedRetention: jacket.PricingDetail?.CalculatedRetention || 0.0,
      actualPremiumTax: jacket.PricingDetail?.ActualPremiumTax || 0.0,
      calculatedPremiumTax: jacket.PricingDetail?.CalculatedPremiumTax || 0.0,
      prevPolicyCoverageType: jacket.PricingDetail?.PrevPolicyCoverageType || "",
      isReissue: jacket.PricingDetail?.IsReissue || false,
      isSimultaneous: jacket.PricingDetail?.IsSimultaneous || false,
      isLeasehold: jacket.PricingDetail?.IsLeasehold || false,
      isBilled: jacket.PricingDetail?.IsBilled,
      lastBilledDate: jacket.PricingDetail?.LastBilledDate,
      isBillable: jacket.PricingDetail?.IsBillable,
      isPrevPolicyLast10Yrs: jacket.PricingDetail?.IsPrevPolicyLast10Yrs,
      isReadyToBeBilled: jacket.PricingDetail?.IsReadyToBeBilled,
      altPricingReferenceID: jacket.PricingDetail?.AltPricingReferenceID,
      lastErrorMessage: jacket.PricingDetail?.LastErrorMessage,
      isFeeError: jacket.PricingDetail?.IsFeeError,
      //isManualFee: jacket.PricingDetail?.IsManualFee,

      // Location info
      contractID: jacket.PricingDetail?.ContractID,
      legacyID: jacket.PricingDetail?.LegacyID,
      locationID: jacket.PricingDetail?.LocationID,
      locationUniqueID: jacket.PricingDetail?.LocationUniqueID,
      //locationName: jacket.PricingDetail?.LocationName,
      locationDisplayName: jacket.PricingDetail?.LocationDisplayName,
      locationLegalName: jacket.PricingDetail?.LocationLegalName,
      underwriterTypeCode: jacket.PricingDetail?.UnderwriterTypeCode,
    },
    documents: jacket.Documents?.map(buildDocument),
    documentOrderData: createDocumentOrderData(jacket.DocumentOrderData),
    void: {
      date: jacket.VoidedDate,
      dateTime: jacket.VoidedDateTime
        ? new Date(jacket.VoidedDateTime)
        : undefined,
      reason: jacket.VoidReason,
      undoRequestedDate: jacket.UndoVoidRequestedDate,
    },
    effectiveDate: getEffectiveDate(jacket.EffectiveDate),
    jacketAdditionalCharges: mapJacketAdditionalChargesFromApi(jacket.JacketAdditionalCharges),
    jacketSpecialCharges: mapJacketSpecialChargesFromApi(jacket.JacketSpecialCharges),
    endorsements: mapEndorsementsFromApi(jacket.Endorsements),
    formDetails: mapFormDetailsFromApi(jacket.JacketFormDetails),
    formDetailsCopy: mapFormDetailsFromApi(jacket.JacketFormDetails),
    jacketSignatures: mapJacketSignaturesFromApi(jacket.JacketSignatures),
    newPremiumAmount: getPremiumAmount(jacket),
    errorMessage: jacket.ErrorMessage,
    isValidationRequired: false,
    displayOrder: jacket.DisplayOrder || 0,
    excludePremium: false,

    // Location info
    altaRegistryID: jacket.ALTARegistryID,
    // contractID: jacket.PricingDetail?.ContractID,
    // legacyID: jacket.PricingDetail?.LegacyID,
    territoryID: jacket.TerritoryID,
    locationID: jacket.PricingDetail?.LocationID,
    //locationName: jacket.PricingDetail?.LocationName,
    locationDisplayName: jacket.PricingDetail?.LocationDisplayName,
    locationLegalName: jacket.PricingDetail?.LocationLegalName,
    
  };

  return newJacket;
};

const mapJacketAdditionalChargesFromApi = (
  jacketAdditionalCharges?: JacketAdditionalCharge[]
): UIModel.JacketAdditionalCharge[] => {

  let mappedJacketAdditionalCharges: UIModel.JacketAdditionalCharge[]  = [];
  
  if (jacketAdditionalCharges && jacketAdditionalCharges.length > 0) {
    mappedJacketAdditionalCharges = jacketAdditionalCharges.map((apiAdc) => {
      const uiAdc: UIModel.JacketAdditionalCharge = {
        integrationKey: apiAdc.IntegrationKey || uuidv4(),
        jacketAdditionalChargeID: apiAdc.JacketAdditionalChargeID,
        filePricingDetailID: apiAdc.FilePricingDetailID,
        productItemID: apiAdc.ProductItemID,
        orderID: apiAdc.OrderID,
        fileID: apiAdc.FileID,
        additionalChargeType: apiAdc.AdditionalChargeType,
        additionalChargeFee: apiAdc.AdditionalChargeFee,
        underwriterTypeCode: apiAdc.UnderwriterTypeCode,
        pricingDetail: mapUIPricingDetail(apiAdc.PricingDetail),
      };

      return uiAdc;
    });
  }
  return mappedJacketAdditionalCharges ;
};

const mapJacketSpecialChargesFromApi =  (
  jacketSpecialCharges?: JacketSpecialCharge[]
): UIModel.JacketSpecialCharge[] => {

  let mappedJacketSpecialCharges: UIModel.JacketSpecialCharge[]  = [];
  
  if (jacketSpecialCharges && jacketSpecialCharges.length > 0) {
    mappedJacketSpecialCharges = jacketSpecialCharges.map((apiSpc) => {
      const uiSpc: UIModel.JacketSpecialCharge = {
        integrationKey: apiSpc.IntegrationKey || uuidv4(),
        jacketSpecialChargeID: apiSpc.JacketSpecialChargeID,
        filePricingDetailID: apiSpc.FilePricingDetailID,
        productItemID: apiSpc.ProductItemID,
        orderID: apiSpc.OrderID,
        fileID: apiSpc.FileID,
        transCode: apiSpc.TransCode,
        transCodeDescription: apiSpc.TransCodeDescription,
        underwriterTypeCode: apiSpc.UnderwriterTypeCode,
        pricingDetail: mapUIPricingDetail(apiSpc.PricingDetail),
      };

      return uiSpc;
    });
  }
  return mappedJacketSpecialCharges ;
};

const mapEndorsementsFromApi = (endorsements?: Endorsement[]) => {
  if (!endorsements || endorsements.length === 0) return [];
  endorsements.sort((a: Endorsement, b: Endorsement) =>
    a.EndorsementStatusTypeCode === EndorsementStatusType.Pending
      ? 1
      : Boolean(b.IsSFE) > Boolean(a.IsSFE)
        ? 1
        : Boolean(b.IsSFE) === Boolean(a.IsSFE)
          ? a.EndorsementName > b.EndorsementName
            ? 1
            : -1
          : -1
  );

  const mappedEndorsements: EndorsementUI[] = endorsements.map((e, index) => {
    return {
      errorMessage: e.ErrorMessage,
      isDirty: e.IsDirty,
      identityKey: e.IdentityKey,
      effectiveDate: e.EffectiveDate ? new Date(e.EffectiveDate) : null,
      endorsementID: e.EndorsementID,
      productItemID: e.ProductItemID,
      endorsementName: e.EndorsementName,
      endorsementStatusTypeCode: e.EndorsementStatusTypeCode,
      endorsementNumber: e.EndorsementNumber,
      isSFE: e.IsSFE === 1 ? true : false,
      isSelected: e.IsSFE === 1 ? true : false,
      issueDate: e.IssueDate, // issueDate,
      issueDateTime: e.IssueDateTime ? new Date(e.IssueDateTime) : null,
      revisionDate: e.RevisionDate,
      revisionDateTime: e.RevisionDateTime
        ? new Date(e.RevisionDateTime)
        : null,
      voidedDate: e.VoidedDate,
      voidedDateTime: e.VoidedDateTime ? new Date(e.VoidedDateTime) : null,
      filePricingDetailID: e.PricingDetail?.FilePricingDetailID || 0,
      integrationKey: e.IntegrationKey,
      altEndorsementID: e.AltEndorsementID,
      endorsementVersion: e.EndorsementVersion,
      opaIdentifier: e.OPAIdentifier,
      isIssuedAfterJacket: e.IsIssuedAfterJacket,
      pricingDetail: {
        filePricingDetailID: e.PricingDetail?.FilePricingDetailID
          ? e.PricingDetail?.FilePricingDetailID
          : 0,
        fileID: e.PricingDetail?.FileID ? e.PricingDetail?.FileID : 0,
        filePricingID: e.PricingDetail?.FilePricingID || 0,
        orderID: e.PricingDetail?.OrderID ? e.PricingDetail?.OrderID : 0,
        liability: e.PricingDetail?.Liability
          ? e.PricingDetail?.Liability
          : 0.0,
        integrationKey: e.PricingDetail?.IntegrationKey
          ? e.PricingDetail?.IntegrationKey
          : uuidv4(),
        transCode: e.PricingDetail?.TransCode,
        transCodeDescription: e.PricingDetail?.TransCodeDescription,
        sortId: index,
        altPricingReferenceID: e.PricingDetail?.AltPricingReferenceID,
        pricingRateType: e.PricingDetail?.PricingRateType,
        pricingRateTypeData: e.PricingDetail?.PricingRateTypeData,
        actualFee: e.PricingDetail?.ActualFee || 0.0,
        originalActualFee: e.PricingDetail?.ActualFee || 0.0,
        calculatedFee: e.PricingDetail?.CalculatedFee || 0.0,
        actualRiskRate: e.PricingDetail?.ActualRiskRate || 0.0,
        calculatedRiskRate: e.PricingDetail?.CalculatedRiskRate || 0.0,
        actualRemittance: e.PricingDetail?.ActualRemittance || 0.0,
        calculatedRemittance: e.PricingDetail?.CalculatedRemittance || 0.0,
        actualRetention: e.PricingDetail?.ActualRetention || 0.0,
        calculatedRetention: e.PricingDetail?.CalculatedRetention || 0.0,
        actualPremiumTax: e.PricingDetail?.ActualPremiumTax || 0.0,
        calculatedPremiumTax: e.PricingDetail?.CalculatedPremiumTax || 0.0,

        isBilled: e.PricingDetail?.IsBilled,
        //lastReportedDate: e.PricingDetail?.LastReportedDate,
        isBillable: e.PricingDetail?.IsBillable,
        isReadyToBeBilled: e.PricingDetail?.IsReadyToBeBilled,

        // Location info
        contractID: e.PricingDetail?.ContractID,
        legacyID: e.PricingDetail?.LegacyID,
        locationID: e.PricingDetail?.LocationID,
        //locationName: e.PricingDetail?.LocationName,
        locationDisplayName: e.PricingDetail?.LocationDisplayName,
        locationLegalName: e.PricingDetail?.LocationLegalName,
        locationUniqueID: e.PricingDetail?.LocationUniqueID,
        underwriterTypeCode: e.PricingDetail?.UnderwriterTypeCode,
        lastErrorMessage: e.PricingDetail?.LastErrorMessage,
        isFeeError: e.PricingDetail?.IsFeeError,
        //isManualFee: e.PricingDetail?.IsManualFee,
      },

      // Also needed to set endorsement's location info
      // Location info
      contractID: e.PricingDetail?.ContractID,
      legacyID: e.PricingDetail?.LegacyID,
      locationID: e.PricingDetail?.LocationID,
      locationDisplayName: e.PricingDetail?.LocationDisplayName,
      locationLegalName: e.PricingDetail?.LocationLegalName,
      locationUniqueID: e.PricingDetail?.LocationUniqueID,
      underwriterTypeCode: e.PricingDetail?.UnderwriterTypeCode,
    };
  });

  return mappedEndorsements;
};

const getEffectiveDate = (effectiveDate: string | null | undefined) => {
  let newDate = undefined;

  if (effectiveDate) {
    if (effectiveDate !== "2001-01-01T00:00:00")
      newDate = new Date(effectiveDate) || new Date();
  }

  return newDate;
};

const mapFormDetailsFromApi = (formDetails?: JacketFormDetail[]) => {
  if (!formDetails || formDetails.length === 0) return [];
  return formDetails.map((e) => {
    return {
      identityKey: e.IdentityKey,
      integrationKey: e.IntegrationKey,
      fileID: e.FileID,
      orderID: e.OrderID,
      productItemID: e.ProductItemID,
      formID: e.FormID,
      formDetailID: e.FormDetailID,
      formDetailName: e.FormDetailName,
      formDetailDisplayName: e.FormDetailName,
      formDetailValue: e.FormDetailValue,
      formDetailType: e.FormDetailType,
      jacketFormDetailID: e.JacketFormDetailID,
      filePricingDetailID: e.FilePricingDetailID,
      FPDIdentityKey: e.FPDIdentityKey,
    };
  });
};

const mapJacketSignaturesFromApi = (jacketSignatures?: JacketSignature[]): JacketSignatureUI[] => {
  if (!jacketSignatures || jacketSignatures.length === 0) return [];
  return jacketSignatures.map((s) => {
    return {
      identityKey: s.IdentityKey,
      integrationKey: s.IntegrationKey,
      jacketSignatureID: s.JacketSignatureID,
      productItemID: s.ProductItemID,
      orderID: s.OrderID,
      fileID: s.FileID,
      filePricingDetailID: s.FilePricingDetailID,
      signatureTypeCode: s.SignatureTypeCode,
      signatureImageID: s.SignatureImageID,
      signatureImageFormatTypeCode: s.SignatureImageFormatTypeCode,
      signatureImageName: s.SignatureImageName,
      signatureImageData: s.SignatureImageData,
      fpdIdentityKey: s.FPDIdentityKey,
    };
  });
};

const getPremiumAmount = ({
  JacketFormDetails,
  PricingDetail,
}: JacketProduct): number => {
  if (!JacketFormDetails) {
    return PricingDetail && PricingDetail.ActualFee
      ? PricingDetail.ActualFee
      : 0;
  }

  const premiumFormDetail = JacketFormDetails.filter(
    (detail: JacketFormDetail) =>
      detail.FormDetailName === "Premium" ||
      detail.FormDetailName === "Premium (for Sch A only)"
  );

  if (premiumFormDetail.length === 0) return 0;
  return (
    convertToNumber(premiumFormDetail[0].FormDetailValue?.replace("$", "")) || 0
  );
};

export { buildJacket };
